import React from 'react'
import UserService from "../services/user.service";
// import { useAuth } from "../contexts/AuthContext"
// import { Card, Button, Accordion } from 'react-bootstrap'

export default function BoardUser(props) {
    // const {currentUser} = useAuth()

        console.log('in componentDidMount (board-user.component)...')
        
        UserService.getUserBoard().then(
          response => {
            this.setState({
              content: response.data
            });
          },
          error => {
              console.log('got an error...')
            this.setState({
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            });
          }
        );
      

    return (
        <div className="container">
          <header className="jumbotron">
            <h3>{this.state.content}</h3>
          </header>
        </div>
      );
}