import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/auth.service";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const { fbLogin } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const status = new URLSearchParams(window.location.search).get("error");
    if (status === "AAE") {
      setError(
        "You already have an account using this Email address. Please Login using Email."
      );
    }
  }, [error]);

  async function handleFB(e) {
    //need to handle user that has signed in using Email previously
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const log = await fbLogin();
      console.log("log...");
      console.log(log);

      const uid = log.user.uid;
      const email = log.additionalUserInfo.profile.email;
      // console.log("paramentes set...heading to login from handleFB...");
      await authService
        .login(email)
        .then((response) => {
          // console.log("login response...");
          // console.log(response);
          if (response === "404") {
            // console.log("caught 404 in response...");
            authService.register(uid, email).then((response) => {
              if (response.status === 200) {
                authService.login(email);
              }
            });
          } else if (response === 200) {
            console.log("user alread registered...logging in...");
            authService.login(email);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      navigate("/");
    } catch (err) {
      console.log(err);
      // setError("Failed to log in")
      // setError(err);
      navigate("/login/?error=AAE");
    }
    setLoading(false);
  }

  async function handleSubmit(e) {
    // console.log("in Login: handleSubmit...");
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // await login(emailRef.current.value, passwordRef.current.value)
      // const logres = await login(
      const res = await login(
        emailRef.current.value,
        passwordRef.current.value
      );
      // console.log("res...");
      // console.log(res);
      //move to authContext
      // console.log("logres...");
      // console.log(logres);
      // await authService.login(logres.user.uid)
      // await authService.login(logres.user.uid, emailRef.current.value)
      await authService.login(emailRef.current.value);

      navigate("/");
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }

  return (
    <Container>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Log In</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <div className="border border-3 border-info rounded-3 p-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100 mt-2" type="submit">
                LOG IN
              </Button>
            </div>
            <div className="w-100 text-center">--- OR ---</div>
            <Button
              disabled={loading}
              className="w-100 mt-2"
              onClick={handleFB}
            >
              Log In with Facebook
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Not a member? <Link to="/signup">SIGN UP</Link>
      </div>
    </Container>
  );
}
