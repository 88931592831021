import React, { useContext, useEffect, useState } from "react"
import { auth, provider } from '../firebase'
//import { getAuth, signInWithEmailAndPassword } from '../firebase/auth'

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

// export function getAuth(){

// }

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
        //console.log(items.PromiseResult.user)
        // return items
    }

    function fbLogin() {
        console.log('in AuthContext: fbLogin...')
        return auth.signInWithPopup(provider)
    }
        
    // function fbLogout () {
    //     return auth.sign
    // }

    function logout () {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signup,
        login,
        fbLogin,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
