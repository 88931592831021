import Top from "./Top";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import React, { useState } from "react";
import { send } from "emailjs-com";
import { useAuth } from "../contexts/AuthContext";
import { CardNumberElement } from "@stripe/react-stripe-js";

const handleSubmit = (e) => {
  e.preventDefault();
};
export default function BeContributer() {
  const { currentUser } = useAuth();

  const [error, setError] = useState("");
  const [msgColor, setMsgColor] = useState("danger");
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    message: "",
    email: currentUser.email,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(toSend);
    send("service_9qcu4ku", "template_b9y0jij", toSend, "WzT5T-VG2PUvDHBTd")
      .then((response) => {
        setMsgColor("succcess");
        setError("Thank you for your interest.  You should hear from us soon!");

        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        setMsgColor("danger");
        setError(
          "Something went wrong.  Please check th form for errors, or send us an Email"
        );
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Container className="d-flex my-auto">
      <Row>
        <Top />
        <Row className="bg-secondary text-white">
          <h2>
            Congratulations! You have been accepted as a Contributor to the
            MatSu Network!
          </h2>
          <p>
            You now have access to the Contributer side of the site. Follow
            these steps to get up and streaming in minutes:
          </p>
        </Row>
        <Row className="pt-3">
          <Card className="bg-info">
            <Card.Title>Create a Subscription:</Card.Title>
            <Card.Body>
              <h6>
                <u>
                  A subscription is what the end user will purchase in order to
                  gain access to your stream.
                </u>
              </h6>
              <ul>
                <li>
                  Click your Email address on the top right side of the page
                </li>
                <li>Select "Subscription Page"</li>
                <li>Click the Yellow "New Subscription" button</li>
              </ul>
              <p>
                <b>SUBSCRIPTION NAME</b>
                <br></br>
                Give your subscription a <u>SHORT</u> meaningful name that the
                user will understand. It should reflect what the subscription
                buys.
              </p>
              <p>
                <b>DESCRIPTION</b>
                <br></br>
                Give a good description of your event so the user fully
                understands what this shuscription will give them access to
              </p>
              <p>
                <b>PRICE</b>
                <br></br>
                Set a price for your subscription in dollars and cents (e.g.
                1.99)
              </p>
              <p>
                {" "}
                <b>SINGLE EVENT</b>
                <br></br>
                You can create a subscription that purchases many events or one
                that purchases only one event. You set that function by
                selecting the "SINGLE EVENT" box. If this box is selected, and
                the user purchases this subscription, they will gain access only
                to the event the subscription is linked to (more on this later).
              </p>
              <Row className="bg-warning p-3">
                <Col>
                  Click the blue{" "}
                  <span className="bg-primary p-2 text-white rounded">
                    SAVE
                  </span>{" "}
                  button at the top right of the page to commit this
                  subscription
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br></br>
        </Row>
        <Row className="pt-3">
          <Card className="bg-info">
            <Card.Title>Create an Event:</Card.Title>
            <Card.Body>
              <h6>
                <u>
                  An event describes the live stream you will be broadcasting.
                  It is valid only for a single stream. To stream multiple
                  events, create an event for each one.
                </u>
              </h6>
              <ul>
                <li>
                  Click on your Email address again and then select "Event Page"
                </li>
                <li>Select "Event Page"</li>
                <li>Click the yellow "New Event" button</li>
              </ul>
              <p>
                <b>EVENT</b>
                <br></br>
                This field is used to categorize the content of the live stream.
                For sports, this would be "Prep Football" for example
              </p>
              <p>
                <b>VENUE</b>
                <br></br>
                This is the physical location of the source of the live stream.
                Make sure you are careful to spell the Venue exactly the same
                for each strem originating from it. Otherwise, you may have
                multiple Venues with the same name if the streams are sorted by
                Venue
              </p>
              <p>
                <b>MARQUEE</b>
                <br></br>
                Give your event a <u>SHORT</u> meaningful name that the user
                will understand. For sports, this would be "XXX vs YYY"
              </p>
              <p>
                <b>DATE</b>
                <br></br>
                Choose the date of the live stream from the Date picker
              </p>
              <p>
                <b>TIME</b>
                <br></br>
                Choose the starting time of your live stream from the time
                picker. It should be the time that stream content is expected to
                begin as opposed to when the stream goes live prior to content.
              </p>
              <p>
                <b>DESCRIPTION</b>
                <br></br>
                Use this field to give a long description of your stream. It
                should include all relevant information to your stream not
                covered in the Event title.
              </p>
              <p>
                <b>HOST CHANNEL</b>
                <br></br>
                Choose the channel that will be hosting your live stream. You
                may have multiple channels based on where you are streaming from
                and whether you are charging for content or not. If there is no
                channel available to pick, please contact the administrator to
                assign you a channel. A channel selection is required since this
                is what will be hosting your live stream.
              </p>
              <p>
                <b>SUBSCRIPTIONS</b>
                <br></br>
                Choose what subscriptions you would like to attach to your live
                stream. You can choose as many relevant subscriptions as you
                want. If the channel is being offered at no charge, do not
                select a subscription. To create soubscriptions, see above.
              </p>
              <Row className="bg-warning p-3">
                <Col>
                  Click the blue{" "}
                  <span className="bg-primary p-2 text-white rounded">
                    SAVE
                  </span>{" "}
                  button at the top right of the page to commit this event
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Row>
    </Container>
  );
}
