import axios from "axios";

//const API_URL = "http://localhost:3001/api/auth/";
// const API_URL = "https://matsunetwork.com/api/auth/";
// const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL})
//axios.defaults.baseURL = process.env.REACT_APP_API_URL + "auth/";
class AuthService {
  // login(username, password) {

  fblogin(email) {
    console.log("in fbsignin (auth.service)...");
    return (
      axios
        //.post("fbsignin", { email })
        .post(
          "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/fbSignin",
          { email }
        )
        .then((response) => console.log(response))
    );
  }

  login(email) {
    console.log("in auth.service: login");
    console.log(email);
    return (
      axios
        //.post("signin", {
        .post(
          "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/signin",
          {
            email,
            // uid
          }
        )
        .then((response) => {
          // if (response.status === 404) {console.log('404')}
          // if (!response.uid) {console.log('NULL')}
          // console.log(response)
          // console.log("in auth.service login response...");
          // console.log(response.data);
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          // console.log('auth.service response...')
          // console.log(response)
          return response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.log("WE CAUGHT IT!");
            //we need to have the firebase uid from the facebook login
            // await this.register(uid,email)
            // this.login(uid, email)
            return error.response.status;
          }
        })
    );
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(uid, email) {
    // console.log("in register...");
    //return axios.post("signup", {
    return axios.post(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/signup",
      {
        uid,
        email,
      }
    );
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
