import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import logo from "../images/Header.jpg";
import {
  Container,
  Button,
  Navbar,
  Row,
  Nav,
  NavDropdown,
  Col,
  Stack,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

export default function Top() {
  //const [visible, setVisible] = useState(true)
  // Authorization
  const { currentUser, logout } = useAuth();
  const [localUser, setLocalUser] = useState(AuthService.getCurrentUser());

  //const [showUser, setShowUser] = useState(false);
  const [showAdmin, setShowAdmin] = useState("none");
  const [showContributer, setShowContributer] = useState("none");

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [live, setLive] = useState("/?live=true");
  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case "profile":
        navigate("/update-profile");
        break;
      case "event":
        navigate("/admin/events");
        break;
      case "subscription":
        navigate("/admin/subscriptions");
        break;
      case "edit":
        navigate("/admin/users");
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // console.log("in Top.js: useEffect...");
    // console.log("props...");
    // console.log(props);
    if (!localUser) {
      return;
    }
    // console.log('localUser.roles...')
    // console.log(localUser.roles)
    setShowContributer(
      localUser.roles.includes("ROLE_CONTRIBUTER") ||
        localUser.roles.includes("ROLE_ADMIN")
        ? "block"
        : "none"
    );
    setShowAdmin(localUser.roles.includes("ROLE_ADMIN") ? "block" : "none");
  }, [localUser]);

  function setVisibility(flag) {
    return flag ? "visible" : "hidden";
  }
  // function setDisplay(flag) {
  //   return currentUser && flag ? "inline" : "none";
  // }

  async function handleLogout() {
    setError("");

    try {
      await logout()
        .then(setLocalUser(AuthService.logout()))
        .then(navigate("/"));
    } catch {
      setError("Failed to Log Out");
    }
  }

  const handleLive = (e) => {
    console.log("in Dashboard: handleSort...");
    console.log(e);
    console.log("---");
    // sortRef.current = e;
    setLive("/?live=" + e);
    console.log("live...");
    console.log(live);
  };
  // console.log(currentUser)
  return (
    <Row className="bg-dark text-white">
      <img src={logo} alt="Logo" />

      <Navbar bg="dark" variant="dark" expand="md">
        <Navbar.Brand href="/">MSN</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">LIVE</Nav.Link>
            <Nav.Link href="/oddash">ON-DEMAND</Nav.Link>
            <Nav.Link href="/contributer">Be a Contributer</Nav.Link>
            <Nav.Link href="/streamhelp" style={{ display: showContributer }}>
              Streamer Guide
            </Nav.Link>
          </Nav>
          <div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button
              id="loginButton"
              className="btn-light text-dark me-2"
              style={{ visibility: setVisibility(!currentUser) }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
            <Button
              id="signupButton"
              className="btn-primary me-2"
              style={{ visibility: setVisibility(!currentUser) }}
              onClick={() => navigate("signup")}
            >
              Sign-up
            </Button>
            <NavDropdown
              style={{ visibility: setVisibility(currentUser) }}
              title={currentUser ? currentUser.email : "Menu"}
              id="nav-dropdown"
              onSelect={handleSelect}
            >
              <NavDropdown.Item eventKey="profile">Profile</NavDropdown.Item>
              <NavDropdown.Item
                eventKey="event"
                style={{ display: showContributer }}
              >
                Event Page
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="subscription"
                style={{ display: showContributer }}
              >
                Subscription Page
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="edit" style={{ display: showAdmin }}>
                Edit Users
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                style={{ display: currentUser ? "inline" : "none" }}
                eventKey="logout"
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Row>
  );
}
