///// THESE WERE WORKING...
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

//import { getAuth } from 'firebase/auth' //was not included

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
});

export const auth = app.auth();
// export const provider = new firebase.auth.FacebookAuthProvider()
export const provider = new firebase.auth.FacebookAuthProvider();
//export const auth = getAuth(app)
export default app;
////////////////

/////// NEW
// import { initializeApp } from "firebase/app";
// import { FacebookAuthProvider, getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_KEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
// };

// const app = initializeApp(firebaseConfig);

// export const auth = getAuth(app); //app.auth();
// // export const provider = new firebase.auth.FacebookAuthProvider()
// export const provider = new FacebookAuthProvider();
// //export const auth = getAuth(app)
// export default app;
