import Top from "./Top";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import React, { useState } from "react";
import { send } from "emailjs-com";
import { useAuth } from "../contexts/AuthContext";

const handleSubmit = (e) => {
  e.preventDefault();
};
export default function BeContributer() {
  const { currentUser } = useAuth();

  const [error, setError] = useState("");
  const [msgColor, setMsgColor] = useState("danger");
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    message: "",
    email: currentUser.email,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(toSend);
    send("service_9qcu4ku", "template_b9y0jij", toSend, "WzT5T-VG2PUvDHBTd")
      .then((response) => {
        setMsgColor("succcess");
        setError("Thank you for your interest.  You should hear from us soon!");

        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        setMsgColor("danger");
        setError(
          "Something went wrong.  Please check th form for errors, or send us an Email"
        );
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Container className="align-content-flex-start">
      <Top />
      <Card>
        <Card.Body>
          Do you have family, friends or relatives out of town that would like
          to see a certain event live? We have made it simple for you to make
          that happen!&nbsp;
          {currentUser ? (
            <></>
          ) : (
            <>
              <a href="/signup">Sign up</a> to become a Mat-Su Network streaming
              contributer today!
            </>
          )}
          Live stream your local event and get paid for your time and effort.
          All you need is a mobile device with a good internet connection to
          begin. Once you are signed into the Mat-Su network, you will see a
          form below. Fill it out to request access to the contributer area of
          the Mat-Su Network. Once you are approved, you can set up your own
          events and charge whatever price you want for public access to your
          content.
        </Card.Body>
        {currentUser ? (
          <>
            <Form onSubmit={handleSubmit}>
              <div> {error && <Alert variant={msgColor}>{error}</Alert>}</div>
              <Form.Group id="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="from_name"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="888-888-8888"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group id="description">
                <Form.Label>
                  Tell us about what you plan on streaming:
                </Form.Label>
                <textarea
                  name="message"
                  className="formControl fluid"
                  style={{ width: "100%", height: "100px" }}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={onSubmit}>
                Submit
              </Button>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Card>
    </Container>
  );
}
