// import { useAuth } from '../contexts/AuthContext'

export default function AuthHeader() {
  console.log('in auth-header.js...')
    //authenticate user
    // if (currentUser) {
       const user = JSON.parse(localStorage.getItem('user'));
      //const user = currentUser.user
      if (user && user.accessToken) {
        return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
      } else {
        return {};
      }
    // } 
}