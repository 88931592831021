import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import AuthService from '../services/auth.service'

export default function ContributorRoute({children}){
  const { currentUser } = useAuth()
  const localUser =  AuthService.getCurrentUser()
  var authorized = false
  // localUser.roles.map((role, index) => {
  localUser.roles.forEach(role => {
    //use in instead
    breakme: if ((role === 'ROLE_CONTRIBUTER') || (role === 'ROLE_ADMIN')) { 
      authorized = true 
      break breakme;
    }  
  })
  // make sure that only contributer or highher can access

  return (currentUser && authorized) ? children : <Navigate to='/'/>
}