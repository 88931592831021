import React, { Suspense, lazy } from "react";
import Signup from "./signup";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import Dashboard from "./Dashboard";

//import ODDash from "./ODDash";
import Login from "./Login";
import ContributorRoute from "./ContributorRoute";
import AdminRoute from "./AdminRoute";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
//import EventList from "./EventList";
import BoardUser from "./BoardUser";
import Home from "./home.component";
//import EventsForm from "./EventsForm";
//import EditUsers from "./EditUsers";
//import EditSubscriptions from "./EditSubscriptions";
import BeContributer from "./BeContributer";
import StreamGuide from "./StreamGuide";
const ODDash = lazy(() => import("./ODDash"));
const Dashboard = lazy(() => import("./Dashboard"));
const EditUsers = lazy(() => import("./EditUsers"));
const EventList = lazy(() => import("./EventList"));
const EventsForm = lazy(() => import("./EventsForm"));
const EditSubscriptions = lazy(() => import("./EditSubscriptions"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/oddash" element={<ODDash />} />
            <Route path="/contributer" element={<BeContributer />} />

            <Route path="/home" element={<Home />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/eventList" element={<EventList />} />
            <Route path="/user" element={<BoardUser />} />
            <Route
              exact
              path="/admin/events"
              element={
                <ContributorRoute>
                  <EventsForm />
                </ContributorRoute>
              }
            />
            <Route
              exact
              path="/streamhelp"
              element={
                <ContributorRoute>
                  <StreamGuide />
                </ContributorRoute>
              }
            />
            <Route
              exact
              path="/admin/subscriptions"
              element={
                <ContributorRoute>
                  <EditSubscriptions />
                </ContributorRoute>
              }
            />
            <Route
              exact
              path="/admin/users"
              element={
                <AdminRoute>
                  <EditUsers />
                </AdminRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Suspense>
    </Router>
  );
}

export default App;
