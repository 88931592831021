import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = 'http://localhost:3001/api/user/';
//const API_URL = "https://matsunetwork.com/api/user/";
//axios.defaults.baseURL = process.env.REACT_APP_API_URL + "user/";

class UserService {
  getPublicContent() {
    // return axios.get(API_URL + "all");
    return axios.get("all");
  }

  getAllUsers() {
    // return axios.get("allUsers");
    return axios.get(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/users"
    );
  }

  updateUser(document) {
    // console.log("in user.service: updateUser...");
    // console.log("document...");
    // console.log(document);
    // return axios.post("updateUser", document);
    return axios.post(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/updateUser",
      document
    );
  }

  getUserBoard() {
    return axios.get("user", { headers: authHeader() });
  }

  getContributerBoard() {
    return axios.get("mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get("admin", { headers: authHeader() });
  }

  getRoles() {
    // console.log("in user.service getRoles...");
    // return axios.get("roles");
    return axios.get(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/getRoles"
    );
  }
  getRoleName(roleId) {
    return axios.post(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/roleName",
      roleId
    );
  }

  getRoleIds(roleList) {
    // return axios.post("rolesNames", roleList);
    return axios.post(
      "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/roleId",
      roleList
    );
  }

  getUser(uid) {
    // return axios.post("users", uid).then(
    return (
      axios
        .post(
          "https://us-west-2.aws.data.mongodb-api.com/app/matsunetwork-rbmbo/endpoint/api/getUser",
          uid
        )
        .then((response) => {
          return response;
        })
        // (error) => {
        //   console.log("Error: " + error);
        //   return "error";
        // }
        .catch((error) => {
          return "error";
        })
    );

    // );
  }
}

export default new UserService();
