import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfRef = useRef();
  const { signup } = useAuth();
  const { login } = useAuth();
  const { fbLogin } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfRef.current.value) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setLoading(true);
      const res = await signup(
        emailRef.current.value,
        passwordRef.current.value
      );
      // add uid to backend user db with roles
      await AuthService.register(res.user.uid, res.user.email);

      await login(emailRef.current.value, passwordRef.current.value);

      await AuthService.login(res.user.email);

      navigate("/");
    } catch (err) {
      setError("Account not created: " + err.code);
    }
    setLoading(false);
  }

  async function handleFB(e) {
    //need to handle user that has signed in using Email previously
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const log = await fbLogin();
      // console.log("log...");
      // console.log(log);

      const uid = log.user.uid;
      const email = log.additionalUserInfo.profile.email;
      // console.log("paramentes set...heading to login from handleFB...");
      await AuthService.login(email)
        .then((response) => {
          // console.log("login response...");
          // console.log(response);
          if (response === "404") {
            // console.log("caught 404 in response...");
            AuthService.register(uid, email).then((response) => {
              if (response.status === 200) {
                AuthService.login(email);
              }
            });
          } else if (response === 200) {
            console.log("user alread registered...logging in...");
            AuthService.login(email);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      navigate("/");
    } catch (err) {
      console.log(err);
      // setError("Failed to log in")
      // setError(err);
      navigate("/login/?error=AAE");
    }
    setLoading(false);
  }

  return (
    <Container>
      <div className="justify-content-center">
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Sign Up</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <div className="border border-3 border-info rounded-3 p-3">
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
                <Form.Group id="password-confirm">
                  <Form.Label>Re-Type Password</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordConfRef}
                    required
                  />
                </Form.Group>
                <Button disabled={loading} className="w-100 mt-2" type="submit">
                  SIGN UP
                </Button>
              </div>
              <div className="w-100 mt-2 text-center">---or---</div>
              <Button
                disabled={loading}
                className="w-100 mt-2"
                onClick={handleFB}
              >
                Sign Up with Facebook
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Already Signed Up? <Link to="/login">LOGIN</Link>
        </div>
      </div>
    </Container>
  );
}
